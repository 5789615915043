@import "./Variables";

footer {
    background: linear-gradient($p3, $p4);
    color: $white;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    .footerColumns {
        flex: 100px 1 1;
        display: flex;
        @include tablet {
            flex-wrap: wrap;
        }
        padding: 10px 0;
        width: 100%;
        max-width: 1140px;
        margin: 0 auto;
        .footerLogo {
            max-width: 300px;
            img {
                max-width: 300px;
                @include mobile {
                    max-width: calc(100% - 80px);
                }
            }
            @include mobile {
                max-width: calc(100% - 80px);
            }
        }
        div {
            flex: 25% 1 1;
            text-align: center;
            @include tablet {
                flex: 100% 0 0;
            }
        }
        ul {
            flex: 25% 1 1;
            @include tablet {
                flex: 100% 0 0;
            }
            text-align: left;
            list-style: none;
            li {
                .socialIcon {
                    margin-right: 5px;
                }
            }
        }
    }
    a {
        color: $white;
    }
    .baseline {
        flex: 22px 0 0;
        background-color: $p5;
        text-align: center;
        padding: 15px 0;
        line-height: 22px;
        font-size: 16px;
    }
}