@import "./Variables";

.genericBtn {
    font-family: $font;
    background: linear-gradient($p3, $p4);
    color: $white;
    appearance: none;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.validation {
    color: $red;
}