@import "../partials/Generic";

.fullScreenLoading {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.2);
    .loadingElement {
        width: 150px;
        height: 150px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: relative;
        .loadingCircle {
            width: 110px;
            height: 110px;
            border-radius: 100px;
            border: 20px solid $grey;
            border-top-color: $p4;
            animation: rotate 0.8s infinite;
        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(45deg);
    }
    to {
        transform: rotate(405deg);
    }
}