@import "../../App.scss";

#inboxContent {
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;
    max-width: 1140px;
    margin: 0 auto;
    .folderView {
        flex: calc(20% - 40px) 0 0;
        padding: 20px 20px;
        .composeBtn {
            position: relative;
            margin: 0 0 20px;
            background-color: lighten($p5, 10%);
            line-height: 30px;
            text-align: center;
            width: 100%;
            height: 30px;
            cursor: pointer;
            color: $p4;
            border-radius: 5px;
            font-weight: 500;
            transition: all 0.2s;
            top: 0px;
            &:hover {
                background-color: $p5;
                top: -5px;
            }
        }
        .folderLabel {
            transition: all 0.2s;
            cursor: pointer;
            border-radius: 5px;
            padding: 5px;
            &:hover {
                background-color: $lightGrey;
            }
            &.active {
                background-color: lighten($p5, 10%);
                &:hover {
                    background-color: $p5;
                }
            }
        }
    }
    .folderContents {
        flex: calc(30% - 40px) 0 0;
        padding: 10px 20px;
        height: 100%;
        overflow-y: auto;
        h2 {
            margin: 0 0 20px;
        }
        .emailMessageItem {
            border-bottom: 1px solid $grey;
            cursor: pointer;
            transition: all 0.2s;
            &:hover {
                background-color: $lightGrey;
            }
            .emailSubject {
                font-weight: bold;
            }
        }
    }
    .previewPane {
        flex: 50% 0 0;
    }
    .emailsBeingComposed {
        position: fixed;
        bottom: 0;
        width: 100%;
        max-width: 1140px;
        right: 50%;
        transform: translateX(50%);
        text-align: right;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .newEmail {
            margin-left: 10px;
            height: 50px;
            .emailHeader {
                position: relative;
                background-color: $grey;
                overflow: hidden;
                display: inline-block;
                height: 50px;
                min-width: 250px;
                border-radius: 5px 5px 0 0;
                cursor: pointer;
                h1 {
                    text-align: center;
                    line-height: 50px;
                    font-size: 30px;
                    margin: 0;
                    color: $white;
                    text-shadow: 0px 0px 2px black;
                    font-weight: 400;
                }
                .icons {
                    display: none;
                }
            }
            .emailBody {
                display: none;
                input {
                    flex: 20px 0 0;
                    display: block;
                    width: calc(100% - 10px);
                    padding: 5px;
                    appearance: none;
                    border: none;
                    border-radius: 0;
                    border-bottom: 1px solid $grey;
                }
                textarea {
                    flex: calc(100% - 10px) 1 1;
                    border: none;
                    width: calc(100% - 10px);
                    padding: 5px;
                    resize: none;
                    height: calc(100% - 10px);
                }
            }
            &.active {
                height: auto;
                box-shadow: 0px 0px 4px black;
                border-radius: 5px 5px 0 0;
                .emailHeader {
                    min-width: 500px;
                    .iconsLeft,
                    .iconsRight {
                        display: inline;
                        color: $white;
                        position: absolute;
                        top: 10px;
                        height: 30px;
                        svg {
                            height: 30px;
                            cursor: pointer;
                        }
                    }
                    .iconsLeft {
                        left: 10px;
                    }
                    .iconsRight {
                        right: 10px;
                    }
                }
                .emailBody {
                    display: flex;
                    flex-direction: column;
                    min-height: 400px;
                    min-width: 500px;
                    background-color: $white;
                }
            }
        }
    }
}