$p3: #B367F5;
$p4: #5E36E0;
$p5: #ABB0FF;
$b1: #023059;
$b2: #1871B0;
$b3: #3D9DD9;
$b4: #0D0D0D;
$b5: #66BEF0;

$selectionPurple: #5E36E0;
$white: #FFFFFF;
$lightGrey: #E6E6E6;
$grey: #C3C3C3;
$red: #bb0000;

$font: 'Montserrat', sans-serif;

$mobile: 480px;
$tablet: 600px;

@mixin tablet {
    @media screen and (max-width: $tablet) {
        @content;        
    }
}

@mixin mobile {
    @media screen and (max-width: $mobile) {
        @content;
    }
}
