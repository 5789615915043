@import "./partials/Generic";

@font-face {
  font-family: 'Montserrat';
  src: url(./assets/Montserrat.ttf);
}
@font-face {
  font-family: 'Montserrat';
  src: url(./assets/Montserrat-Italic.ttf);
  font-style: italic;
}

html, body {
  font-family: $font;
  overscroll-behavior-y: none;
  ::selection {
    background-color: $selectionPurple;
    color: $white;
  }
  &:focus {
    box-shadow: 3px 3px 10px $selectionPurple !important;
  }
  input {
    appearance: none;
    border: 1px solid $grey;
    border-radius: 10px;
    &:focus {
      outline: none;
      box-shadow: 0px 0px 10px $selectionPurple;
    }
  }
  select {
    appearance: none;
    border: 1px solid $grey;
    border-radius: 10px;
    &:focus {
      outline: none;
      box-shadow: 0px 0px 10px $selectionPurple;
    }
  }
  #root {
    overflow: hidden;
  }
}