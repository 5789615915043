@import "../../partials/Generic";

.btnStack {
    display: inline-block;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
    a {
        text-decoration: none;
        margin-bottom: 30px;
    }
    button {
        display: block;
        width: 100%;
        margin-bottom: 30px;
    }
}