@import "../../partials/Generic";

#signupContent {
    text-align: center;
    label {
        display: block;
        input,
        select {
            margin: 10px auto 20px;
            display: block;
            font-family: $font;
            padding: 15px;
            font-size: 20px;
            min-width: 600px;
            box-sizing: border-box;
            @include tablet {
                min-width: 10px;
                width: calc(100% - 40px);
            }
        }
    }
    .validation {
        display: block;
        margin-bottom: 20px;
    }
    .genericBtn {
        margin-top: 20px;
        margin-bottom: 50px;
    }
}