@import "../App.scss";

.componentLoading {
    width: 100%;
    height: 5px;
    background-color: $p5;
    .loadingElement {
        height: 100%;
        width: 25%;
        position: relative;
        left: 0%;
        background-color: $p4;
        animation: horizontalBounce .5s ease-in-out infinite alternate;
    }
}

@keyframes horizontalBounce {
    0% { 
        left: 0%;
    }
    100% {
        left: 75%;
    }
}