@import "../../partials/Generic";

.priceCards {
    display: flex;
    @include tablet {
        flex-wrap: wrap;;
    }
    .priceCard {
        flex: calc(33% - 40px) 0 0;
        margin: 0 10px 30px;
        padding: 10px;
        box-shadow: 1px 1px 3px black;
        border-radius: 15px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        @include tablet {
            flex: calc(100% - 40px) 0 0;
        }
        h3 {
            flex-grow: 0;
            flex-shrink: 0;
            text-align: center;
            color: white;
            margin: -10px; 
            padding: 20px 20px;
            background: linear-gradient($p3, $p4);
        }
        div {
            flex-grow: 1;
        }
        div.priceTag {
            flex-grow: 0;
            text-align: center;
            margin-bottom: 20px;
            font-weight: 600;
            font-size: 24px;
        }
        button {
            flex-grow: 0;
            flex-shrink: 0;
            appearance: none;
            background: linear-gradient($p3, $p4);
            border: none;
            border-radius: 5px;
            color: white;
            padding: 10px;
            font-size: 18px;
            font-weight: bold;
            width: 100%;
        }
    }
}