@import "./Variables";

main {
    min-height: calc(100vh - 270px);
    overflow: hidden;
    .content {
        max-width: 1140px;
        margin: 0 auto;
        @include tablet {
            max-width: calc(100% - 40px);
            margin: 0 20px;
        }
    }
}