header {
    height: 70px;
    overflow: hidden;
    picture.headerLogo {
        margin-left: 20px;
        height: 70px;
        img {
            height: 100%;
        }
    }
}